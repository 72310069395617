import ContentBlocks from "@/components/ContentBlocks";
import HeroSlideshow from "@/components/HeroSlideshow";
import Page from "@/components/Page";
import Seomatic from "@/components/Seomatic";
import useTrack from "@/hooks/useTrack";
import Layout from "@/layouts/Layout";
import craftBatch from "@/lib/craftBatch";
import getCommonServerSideProps from "@/lib/getCommonServerSideProps";
import QueryHomepage from "@/queries/craft/QueryHomepage.graphql";
import state from "@/state";
import { observer } from "mobx-react";
import Head from "next/head";
import React, { useEffect } from "react";

export async function getServerSideProps({
  preview,
  params,
  req,
  res,
  query,
  locale,
}) {
  const commonProps = await getCommonServerSideProps({
    req,
    res,
    query,
    locale,
  });

  const queries = [
    {
      document: QueryHomepage,
      variables: { site: commonProps.site },
    },
  ];

  const response = await craftBatch(queries, query?.token);

  const { pageEntry } = response[0] || {};

  if (!pageEntry) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      ...commonProps,
      pageEntry,
    },
  };
}

const Home = ({ site, pageEntry }) => {
  const hasHeroSlideshow =
    !!pageEntry.heroSlideshow && pageEntry.heroSlideshow.length > 0;

  // Set the header theme to dark
  useEffect(() => {
    state.header.theme = "dark";
  }, []);

  //	Track event
  useTrack(
    "View Homepage",
    "Homepage",
    pageEntry.id,
    pageEntry.title,
    pageEntry.uri,
  );

  return (
    <Page withHero={hasHeroSlideshow}>
      <Seomatic Head={Head} {...pageEntry.seomatic} />
      {site !== "default" && (
        <Head>
          <meta key="robots" name="robots" content="none" />
        </Head>
      )}
      {hasHeroSlideshow && <HeroSlideshow cells={pageEntry.heroSlideshow} />}
      {!!pageEntry.contentBlocks && (
        <ContentBlocks contentBlocks={pageEntry.contentBlocks} />
      )}
    </Page>
  );
};

const HomeLayout = (page) => {
  const { navigation, site, siteLogo, organisation, hideYcnBranding } =
    page.props;

  return (
    <Layout
      site={site}
      navigation={navigation}
      siteLogo={siteLogo}
      organisation={organisation}
      hideYcnBranding={hideYcnBranding}
    >
      {page}
    </Layout>
  );
};

Home.getLayout = HomeLayout;

Home.displayName = "Home";

export default observer(Home);
