import state from "@/state";
import { wrap } from "@popmotion/popcorn";
import clsx from "clsx";
import HeroImage from "components/HeroImage";
import PageControls from "components/PageControls";
import Pager from "components/Pager";
import { AnimatePresence, motion } from "framer-motion";
import { easeInCubic, easeInOutCubic, easeOutQuint } from "lib/constants";
import { isColorDark, isImageDark } from "lib/helpers";
import React, { useEffect, useState } from "react";
import { useInterval } from "react-use";

export const transitionDefaults = { ease: easeInOutCubic, duration: 1 };

const SLIDESHOW_DURATION = 4000; // 4s

const swipeConfidenceThreshold = 100;

const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? "120%" : "-120%",
      transition: {
        ...transitionDefaults,
        ease: direction > 0 ? easeInCubic : easeOutQuint,
      },
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    transition: {
      ...transitionDefaults,
      ease: easeInOutCubic,
    },
  },
  leave: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? "110%" : "-110%",
      transition: {
        ...transitionDefaults,
        ease: direction < 0 ? easeInCubic : easeOutQuint,
      },
    };
  },
};

const HeroSlideshow = ({ cells }) => {
  const [[page, direction], setPage] = useState([0, 0]);
  const [isRunning, setIsRunning] = useState(false);

  const cellIndex = wrap(0, cells.length, page);
  const activeCell = cells[cellIndex];
  const image = activeCell.image[0];
  const imageMobile = activeCell.imageMobile[0];
  const isBackgroundDark = isColorDark(activeCell.backgroundColor);

  /*
		Pagination
	*/

  // Paginate previous or next by the given amount of cells
  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  const handleNext = () => {
    setIsRunning(false);
    paginate(1);
  };

  const handlePrev = () => {
    setIsRunning(false);
    paginate(-1);
  };

  // Go directly to the specified index
  const goToPage = (index) => {
    setIsRunning(false);
    setPage([index, direction]);
  };

  /*
		Player
	*/

  // Toggle whether the slideshow is running or not
  useInterval(() => paginate(1), isRunning ? SLIDESHOW_DURATION : null);

  /*
		Effects
	*/

  // Change header color depending on the image
  useEffect(() => {
    if (!image) return;

    const asyncIsImageDark = async (src) => isImageDark(src);

    asyncIsImageDark(image.url).then((isDark) => {
      state.header.theme = isDark ? "light" : "dark";
    });
  }, [image]);

  /*
		Handlers
	*/

  const handleDragEnd = (e, { offset, velocity }) => {
    const swipe = swipePower(offset.x, velocity.x);

    setIsRunning(false);

    if (swipe < -swipeConfidenceThreshold) {
      paginate(1);
    } else if (swipe > swipeConfidenceThreshold) {
      paginate(-1);
    }
  };

  useEffect(() => {
    setIsRunning(true);
  }, []);

  return (
    <div
      className={clsx(
        "relative z-10 overflow-hidden w-full h-[50vh] lg:h-[66.666vh] lg:min-h-[680px] lg:max-h-[890px]",
      )}
    >
      <div className={clsx("relative w-full h-full")}>
        <div
          className={clsx(
            "bg-black absolute overflow-hidden transition bottom-0 left-6 lg:left-12 top-0 right-0 rounded-bl-xl",
          )}
          style={{ backgroundColor: activeCell.backgroundColor }}
        />

        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            key={page}
            className={clsx(
              "flex absolute left-0 top-0 right-0 bottom-12 lg:bottom-16",
            )}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="leave"
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={handleDragEnd}
          >
            <motion.div
              className={clsx(
                "flex items-center justify-center w-full h-full overflow-hidden bg-black",
              )}
            >
              <HeroImage
                heading={activeCell.heading}
                text={activeCell.text}
                image={image}
                imageMobile={imageMobile}
                direction={direction}
                target={activeCell.target}
              />
            </motion.div>
          </motion.div>
        </AnimatePresence>

        <div
          className={clsx(
            "flex items-center absolute bottom-0 h-12 lg:h-16 left-12 lg:left-12 right-6 lg:right-16",
          )}
        >
          <div className={clsx("")}>
            <Pager
              count={cells.length}
              onClick={goToPage}
              activeIndex={cellIndex}
              theme={isBackgroundDark ? "light" : "dark"}
              size="large"
            />
          </div>
          <div className={clsx("ml-auto")}>
            <PageControls
              onNext={handleNext}
              onPrev={handlePrev}
              theme={isBackgroundDark ? "light" : "dark"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSlideshow;
